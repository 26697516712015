define("discourse/plugins/discourse-interstitials/discourse/controllers/admin-plugins-discourse-interstitial-index", ["exports", "@ember/controller", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/decorators", "discourse-i18n"], function (_exports, _controller, _object, _service, _ajax, _ajaxError, _decorators, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsDiscourseInterstitialIndexController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    showActive = true;
    editInterstitial(interstitial) {
      this.router.transitionTo("adminPlugins.discourse-interstitial.edit", interstitial.id);
    }
    static #_3 = (() => dt7948.n(this.prototype, "editInterstitial", [_object.action]))();
    goToReports() {
      this.router.transitionTo("adminReports.show", "discourse_interstitial");
    }
    static #_4 = (() => dt7948.n(this.prototype, "goToReports", [_object.action]))();
    toggleInactiveInterstitials() {
      this.set("showActive", !this.showActive);
    }
    static #_5 = (() => dt7948.n(this.prototype, "toggleInactiveInterstitials", [_object.action]))();
    interstitials(model, showActive) {
      return model.filterBy("enabled", showActive);
    }
    static #_6 = (() => dt7948.n(this.prototype, "interstitials", [(0, _decorators.default)("model.@each.enabled", "showActive")]))();
    destroyInterstitial(interstitial) {
      this.dialog.confirm({
        message: (0, _discourseI18n.i18n)("discourse_interstitial.destroy_interstitial.confirm"),
        didConfirm: () => {
          interstitial.destroyRecord().then(() => this.send("triggerRefresh")).catch(_ajaxError.popupAjaxError);
        }
      });
    }
    static #_7 = (() => dt7948.n(this.prototype, "destroyInterstitial", [_object.action]))();
    cloneInterstitial(interstitial) {
      this.dialog.yesNoConfirm({
        message: (0, _discourseI18n.i18n)("discourse_interstitial.clone_interstitial.confirm"),
        didConfirm: () => {
          return (0, _ajax.ajax)(`/admin/plugins/discourse-interstitial/interstitials/clone`, {
            type: "POST",
            data: JSON.stringify({
              id: interstitial.id
            }),
            dataType: "json",
            contentType: "application/json"
          }).then(response => {
            this.router.transitionTo("adminPlugins.discourse-interstitial.edit", response.interstitial.id);
          }).catch(_ajaxError.popupAjaxError);
        }
      });
    }
    static #_8 = (() => dt7948.n(this.prototype, "cloneInterstitial", [_object.action]))();
  }
  _exports.default = AdminPluginsDiscourseInterstitialIndexController;
});