define("discourse/plugins/discourse-interstitials/discourse/components/fields/di-field-description", ["exports", "@ember/component", "@ember-decorators/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.description}}
    <p class="control-description">
      {{this.description}}
    </p>
  {{/if}}
  */
  {
    "id": "LgvN3Kta",
    "block": "[[[41,[30,0,[\"description\"]],[[[1,\"  \"],[10,2],[14,0,\"control-description\"],[12],[1,\"\\n    \"],[1,[30,0,[\"description\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"p\"]]",
    "moduleName": "discourse/plugins/discourse-interstitials/discourse/components/fields/di-field-description.hbs",
    "isStrictMode": false
  });
  const DiFieldDescription = dt7948.c(class DiFieldDescription extends _component.default {}, [(0, _component2.tagName)("")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DiFieldDescription);
});