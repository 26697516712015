define("discourse/plugins/discourse-interstitials/discourse/components/fields/di-field-label", ["exports", "@ember/component", "@ember-decorators/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.label}}
    <label class="control-label">
      <span>{{this.label}}</span>
      {{#if this.field.isRequired}}
        <span>*</span>
      {{/if}}
    </label>
  {{/if}}
  */
  {
    "id": "4aeNgPWc",
    "block": "[[[41,[30,0,[\"label\"]],[[[1,\"  \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,\"\\n    \"],[10,1],[12],[1,[30,0,[\"label\"]]],[13],[1,\"\\n\"],[41,[30,0,[\"field\",\"isRequired\"]],[[[1,\"      \"],[10,1],[12],[1,\"*\"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"label\",\"span\"]]",
    "moduleName": "discourse/plugins/discourse-interstitials/discourse/components/fields/di-field-label.hbs",
    "isStrictMode": false
  });
  const DiFieldLabel = dt7948.c(class DiFieldLabel extends _component.default {}, [(0, _component2.tagName)("")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DiFieldLabel);
});