define("discourse/plugins/discourse-interstitials/discourse/controllers/admin-plugins-discourse-interstitial", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service"], function (_exports, _controller, _object, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsDiscourseInterstitialController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "showNewInterstitial", [(0, _computed.equal)("router.currentRouteName", "adminPlugins.discourse-interstitial.index")]))();
    #showNewInterstitial = (() => (dt7948.i(this, "showNewInterstitial"), void 0))();
    newInterstitial() {
      this.router.transitionTo("adminPlugins.discourse-interstitial.new");
    }
    static #_3 = (() => dt7948.n(this.prototype, "newInterstitial", [_object.action]))();
  }
  _exports.default = AdminPluginsDiscourseInterstitialController;
});