define("discourse/plugins/discourse-interstitials/discourse/routes/admin-plugins-discourse-interstitial-index", ["exports", "@ember/object", "discourse/models/group", "discourse/routes/discourse"], function (_exports, _object, _group, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsDiscourseInterstitialIndex extends _discourse.default {
    controllerName = "admin-plugins-discourse-interstitial-index";
    model() {
      return this.store.findAll("discourse-interstitial-interstitial");
    }
    triggerRefresh() {
      this.refresh();
    }
    static #_ = (() => dt7948.n(this.prototype, "triggerRefresh", [_object.action]))();
    setupController(controller, model) {
      _group.default.findAll().then(groups => {
        controller.setProperties({
          allGroups: groups
        });
      });
      controller.setProperties({
        model,
        allGroups: []
      });
    }
  }
  _exports.default = AdminPluginsDiscourseInterstitialIndex;
});