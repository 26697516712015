define("discourse/plugins/discourse-interstitials/discourse/components/cta-button", ["exports", "@ember/component", "@ember-decorators/component", "discourse/lib/decorators", "@ember/template-factory"], function (_exports, _component, _component2, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{d-button
    class=this.ctaButtonClass
    translatedLabel=this.button.title
    action=this.action
    disabled=this.saving
  }}
  */
  {
    "id": "ukYRAfHT",
    "block": "[[[1,[28,[35,0],null,[[\"class\",\"translatedLabel\",\"action\",\"disabled\"],[[30,0,[\"ctaButtonClass\"]],[30,0,[\"button\",\"title\"]],[30,0,[\"action\"]],[30,0,[\"saving\"]]]]]]],[],false,[\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-interstitials/discourse/components/cta-button.hbs",
    "isStrictMode": false
  });
  const CtaButton = dt7948.c(class CtaButton extends _component.default {
    ctaButtonClass(button) {
      if (this.siteSettings.allow_custom_cta_button_classes && button.color) {
        return button.color;
      }
      let link = button.link;
      if (link === "cancel") {
        return "btn-danger";
      }
      if (link === "snooze") {
        return "btn-secondary";
      }
      return "btn-primary";
    }
    static #_ = (() => dt7948.n(this.prototype, "ctaButtonClass", [(0, _decorators.default)("button", "enableCustomClass")]))();
  }, [(0, _component2.tagName)("")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CtaButton);
});