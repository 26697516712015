define("discourse/plugins/discourse-interstitials/discourse/controllers/admin-plugins-discourse-interstitial-edit", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed"], function (_exports, _controller, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsDiscourseInterstitialEditController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "interstitial", [(0, _computed.reads)("model.interstitial")]))();
    #interstitial = (() => (dt7948.i(this, "interstitial"), void 0))();
    refreshPage() {
      this.send("refreshRoute");
    }
    static #_2 = (() => dt7948.n(this.prototype, "refreshPage", [_object.action]))();
    onChangeField(field, identifier, value) {
      (0, _object.set)(field, `metadata.${identifier}`, value);
    }
    static #_3 = (() => dt7948.n(this.prototype, "onChangeField", [_object.action]))();
  }
  _exports.default = AdminPluginsDiscourseInterstitialEditController;
});