define("discourse/plugins/discourse-interstitials/discourse/controllers/admin-plugins-discourse-interstitial-new", ["exports", "@ember/controller", "@ember/object", "@ember/service"], function (_exports, _controller, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsDiscourseInterstitialNewController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    form = null;
    error = null;
    init() {
      super.init(...arguments);
      this._resetForm();
    }
    _resetForm() {
      this.set("form", _object.default.create({
        name: null,
        script: null
      }));
    }
    onCreate(interstitial) {
      this._resetForm();
      this.router.transitionTo("adminPlugins.discourse-interstitial.edit", interstitial.id);
    }
    static #_2 = (() => dt7948.n(this.prototype, "onCreate", [_object.action]))();
  }
  _exports.default = AdminPluginsDiscourseInterstitialNewController;
});