define("discourse/plugins/discourse-interstitials/discourse/components/fields/di-group-field", ["exports", "@ember/component", "@ember/object", "discourse/models/group", "discourse/plugins/discourse-interstitials/discourse/components/fields/di-base-field", "@ember/template-factory"], function (_exports, _component, _object, _group, _diBaseField, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <section class="field group-field">
    <div class="control-group">
      {{fields/di-field-label label=this.label field=this.field}}
  
      <div class="controls">
        {{group-chooser
          content=this.allGroups
          value=this.field.metadata.value
          labelProperty="name"
          onChange=(action "setGroupField")
          options=(hash maximum=this.field.maximum disabled=this.field.isDisabled)
        }}
  
        {{fields/di-field-description description=this.description}}
      </div>
    </div>
  </section>
  */
  {
    "id": "5ExWhclj",
    "block": "[[[10,\"section\"],[14,0,\"field group-field\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n    \"],[1,[28,[35,2],null,[[\"label\",\"field\"],[[30,0,[\"label\"]],[30,0,[\"field\"]]]]]],[1,\"\\n\\n    \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n      \"],[1,[28,[35,3],null,[[\"content\",\"value\",\"labelProperty\",\"onChange\",\"options\"],[[30,0,[\"allGroups\"]],[30,0,[\"field\",\"metadata\",\"value\"]],\"name\",[28,[37,4],[[30,0],\"setGroupField\"],null],[28,[37,5],null,[[\"maximum\",\"disabled\"],[[30,0,[\"field\",\"maximum\"]],[30,0,[\"field\",\"isDisabled\"]]]]]]]]],[1,\"\\n\\n      \"],[1,[28,[35,6],null,[[\"description\"],[[30,0,[\"description\"]]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"section\",\"div\",\"fields/di-field-label\",\"group-chooser\",\"action\",\"hash\",\"fields/di-field-description\"]]",
    "moduleName": "discourse/plugins/discourse-interstitials/discourse/components/fields/di-group-field.hbs",
    "isStrictMode": false
  });
  class DiGroupField extends _diBaseField.default {
    allGroups = null;
    init() {
      super.init(...arguments);
      _group.default.findAll().then(groups => {
        groups.forEach(g => g.name = g.full_name || g.name);
        this.set("allGroups", groups);
      });
    }
    setGroupField(groupIds) {
      this.onChangeField(this.field, groupIds);
    }
    static #_ = (() => dt7948.n(this.prototype, "setGroupField", [_object.action]))();
  }
  _exports.default = DiGroupField;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DiGroupField);
});